<template>
  <div>
    <h1>Your Cart</h1>
    <div>
      <h2 v-if="this.$root.$data.cart.length == 0">No Items in cart yet</h2>
    </div>
    <CartList :products="products" />
  </div>
</template>

<script>
import CartList from "../components/CartList.vue"
export default {
  name: 'CartView',
  components: {
    CartList
  },
  computed: {
    products() {
      return this.$root.$data.cart;
    }
  }
}
</script>

<style scoped>
h1, h2 {
  text-align: center;
}
</style>