<template>
<div class="wrapper">
  <div class="products">
    <div class="product" v-for="(product, index) in products" :key="product.id">
      <div class="pure-g">
        <div class="pure-u-1-2 info-tab">
          <div class="info">
            <h1>{{product.name}}</h1>
            <p>{{product.country}}</p>
          </div>
          <div class="price">
            <h2>{{product.price}}</h2>
            <button class="auto" v-on:click="removeFromCart(index)">Remove From Cart</button>
          </div>
        </div>
        <div class="pure-u-1-2">
          <div class="image">
            <img :src="'/images/products/'+product.image">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'CartList',
  props: {
    products: Array
  },
  methods: {
    removeFromCart(index) {
      if (index === 0) {
        this.$root.$data.cart.splice(0,1);
      } else {
        this.$root.$data.cart.splice(index,1);
      }
    }
  }
}
</script>

<style scoped>
.info-tab {
  position: relative;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  margin: 10px;
  margin-top: 50px;
  width: 400px;
  background: rgba(.1, .1, .1, .1);
}

.product img {
  border: 2px solid #333;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
}

.info {
  background: #F2921D;
  color: #000;
  padding: 10px 30px;
  height: 80px;
}

.info h1 {
  font-size: 16px;
}

h2 {
  font-size: 28px;
  text-align: center;
}

.info p {
  margin: 0px;
  font-size: 10px;
}


.price {
  position: absolute;
  bottom: 0;
  right: 0;
  width:100%;
}

button {
  height: 50px;
  background: red;
  color: white;
  border: none;
  width:100%;
}

button:hover {
  background: darkred;
}
</style>